<template>
  <div>
    <b-card>
      <!-- form -->
      <b-overlay
        :show="loadingOverlay"
        rounded="sm"
      >
        <validation-observer ref="simpleRules">
          <b-form
            autocomplete="off"
            @submit.prevent="validationForm"
          >
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label>Perfil</label>
                  <validation-provider
                    #default="{ errors }"
                    name="perfil"
                    rules="required"
                  >
                    <b-form-select
                      v-model="data.agentePerfilTradeId"
                      :options="agentePerfis"
                      input-id="perfil"
                      text-field="nome"
                      value-field="id"
                      @change="getMoedaRecomendada"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="recomend-moedas"
                  label="Moedas"
                  description="Deixe em branco para aceitar todas as moedas. Use vírgula para separar"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="moedas"
                    vid="Password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group
                        id="recomend-moedas"
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-textarea
                          v-model="data.moeda"
                          rows="6"
                          :state="errors.length > 0 ? false:null"
                          @input="data.moeda = data.moeda.toUpperCase()"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  type="submit"
                >
                  Salvar
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormTextarea, BRow, BCol, BCard, BInputGroup, BOverlay, BFormSelect,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import configuracaoModule from './configuracaoModule'

const CONFIGURACAO_AGENTE_SPOT_STORE_MODULE_NAME = 'configuracao-agente-spot'

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BButton,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BOverlay,
    BFormSelect,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loadingOverlay: false,
      required,
      agentePerfis: [],
      data: {
        moeda: null,
        agentePerfilTradeId: null,
      },
    }
  },

  created() {
    if (!store.hasModule(CONFIGURACAO_AGENTE_SPOT_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_AGENTE_SPOT_STORE_MODULE_NAME, configuracaoModule)
    this.getAgentePerfis()
  },

  beforeDestroy() {
    if (store.hasModule(CONFIGURACAO_AGENTE_SPOT_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_AGENTE_SPOT_STORE_MODULE_NAME)
  },

  methods: {

    getAgentePerfis() {
      this.$store.dispatch('configuracao-agente-spot/getAgentePerfis')
        .then(response => {
          this.agentePerfis = response.data
        })
    },

    getMoedaRecomendada() {
      this.$store.dispatch('configuracao-agente-spot/getMoedaRecomendada', this.data.agentePerfilTradeId)
        .then(response => {
          this.data.moeda = response.data.whiteList
        })
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('configuracao-agente-spot/setMoedaRecomendada', this.data).then(() => {
            this.$swal({
              title: 'Sucesso!',
              text: 'Moedas alteradas com sucesso.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }).catch(error => {
            this.$swal({
              title: 'Error!',
              text: error.response.data[0].erro,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }).finally(() => {
            this.loadingOverlay = false
          })
        }
      })
    },
  },
}
</script>
