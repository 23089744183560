import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getAgentePerfis() {
      return new Promise((resolve, reject) => {
        axios.get('agente/perfil')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    setMoedaRecomendada(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('agente/perfil/alterar-moeda-recomendada', data)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getMoedaRecomendada(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`agente/perfil/${id}/recomendacao`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
