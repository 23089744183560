<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ThumbsUpIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Recomendações</span>
      </template>
      <configuracao-recomendacao />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ConfiguracaoRecomendacao from './ConfiguracaoRecomendacao.vue'

export default {
  components: {
    BTabs,
    BTab,
    ConfiguracaoRecomendacao,
  },
}
</script>
